import school from "../assets/critGraphics/school_color.png";
import nursery from "../assets/critGraphics/nursery.png";
import hospital from "../assets/critGraphics/hospital_color.png";
import gasStation from "../assets/critGraphics/gas_station.png";
import elderly from "../assets/critGraphics/elderly.png";

const schoolSymbol = {
  type: "picture-marker",
  url: school,
  width: "32px",
  height: "32px",
};

const nurserySymbol = {
  type: "picture-marker",
  url: nursery,
  width: "32px",
  height: "32px"
};

const hospitalSymbol = {
  type: "picture-marker",
  url: hospital,
  width: "32px",
  height: "32px"
};

const elderlySymbol = {
  type: "picture-marker",
  url: elderly,
  width: "32px",
  height: "32px"
};

const gasStationSymbol = {
  type: "picture-marker",
  url: gasStation,
  width: "32px",
  height: "32px"
};

export const ecStMichel = {
  coord: {
    type: "point",
    latitude: 45.398161,
    longitude: -74.029166
  },
  info: {
    nom: "École St-Michel",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecSteMadeleine = {
  coord: {
    type: "point",
    latitude: 45.399783,
    longitude: -74.030608
  },
  info: {
    nom: "École Ste-Madeleine",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecPapillonBleu = {
  coord: {
    type: "point",
    latitude: 45.388337,
    longitude: -74.018128
  },
  info: {
    nom: "École du Papillon-Bleu",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecPapillonBleu2 = {
  coord: {
    type: "point",
    latitude: 45.383942,
    longitude: -74.013179
  },
  info: {
    nom: "École du Papillon-Bleu",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecCiteJeunes = {
  coord: {
    type: "point",
    latitude: 45.394441,
    longitude: -74.028221
  },
  info: {
    nom: "École secondaire de la Cité-des-Jeunes",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecPet = {
  coord: {
    type: "point",
    latitude: 45.411367,
    longitude: -74.023043
  },
  info: {
    nom: "École primaire Pierre-Elliott-Trudeau",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecJoseMaria = {
  coord: {
    type: "point",
    latitude: 45.390359,
    longitude: -73.987381
  },
  info: {
    nom: "École primaire José-Maria",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const ecEtincelle = {
  coord: {
    type: "point",
    latitude: 45.561518,
    longitude: -73.858011
  },
  info: {
    nom: "École primaire Pavillon l'Étincelle",
    cat: "Établissement d'enseignement",
  },
  icone: schoolSymbol
}

export const nuAbc = {
  coord: {
    type: "point",
    latitude: 45.398961,
    longitude: -74.027700
  },
  info: {
    nom: "ABC du Village Inc",
    cat: "Garderie",
  },
  icone: nurserySymbol
}

export const nuCoinAmis = {
  coord: {
    type: "point",
    latitude: 45.398650,
    longitude: -74.025725
  },
  info: {
    nom: "Le Coin des Petits Amis",
    cat: "Garderie",
  },
  icone: nurserySymbol
}

export const nuReleve = {
  coord: {
    type: "point",
    latitude: 45.393429,
    longitude: -74.023731
  },
  info: {
    nom: "La Relève",
    cat: "Centre de la petite enfance",
  },
  icone: nurserySymbol
}

export const nuMousses = {
  coord: {
    type: "point",
    latitude: 45.390824,
    longitude: -74.029352
  },
  info: {
    nom: "Les Petits Mousses",
    cat: "Centre de la petite enfance",
  },
  icone: nurserySymbol
}

export const nuRayonsSoleil = {
  coord: {
    type: "point",
    latitude: 45.389883,
    longitude: -74.015593
  },
  info: {
    nom: "Rayons de Soleil",
    cat: "Garderie",
  },
  icone: nurserySymbol
}

export const hoMonteregien = {
  coord: {
    type: "point",
    latitude: 45.39611,
    longitude: -74.025582
  },
  info: {
    nom: "Centre montérégien de réadaptation",
    cat: "Centre de réadaptation",
  },
  icone: hospitalSymbol
}

export const hoProtEnfance = {
  coord: {
    type: "point",
    latitude: 45.403839,
    longitude: -74.03613
  },
  info: {
    nom: "CPEJ de Vaudreuil-Dorion",
    cat: "Centre intégré de santé et de services sociaux",
  },
  icone: hospitalSymbol
}

export const raDelaRive = {
  coord: {
    type: "point",
    latitude: 45.562430,
    longitude: -73.860100
  },
  info: {
    nom: "Appartements de La Rive",
    cat: "Résidence pour personnes âgées",
  },
  icone: elderlySymbol
}

export const ssUltramar = {
  coord: {
    type: "point",
    latitude: 45.404678,
    longitude: -74.032265
  },
  info: {
    nom: "Ultramar",
    cat: "Station service",
  },
  icone: gasStationSymbol
}

export const ssPetroCanada = {
  coord: {
    type: "point",
    latitude: 45.407147,
    longitude: -74.034952
  },
  info: {
    nom: "Petro-Canada",
    cat: "Station service",
  },
  icone: gasStationSymbol
}

export const ssEsso = {
  coord: {
    type: "point",
    latitude: 45.553454,
    longitude: -73.870030
  },
  info: {
    nom: "Esso",
    cat: "Station service",
  },
  icone: gasStationSymbol
}

// export const critGraphics = [ecStMichel, ecSteMadeleine, ecPapillonBleu, ecCiteJeunes, hoMonteregien, hoProtEnfance]

// var lineAtt = {
//   Name: "Keystone Pipeline",
//   Owner: "TransCanada",
//   Length: "3,456 km"
// };



// export var pointGraphic = {
//   geometry: ecStMichel.coord,
//   symbol: schoolSymbol,
//   attributes: ecStMichel.info,
//   popupTemplate: {
//     // autocasts as new PopupTemplate()
//     title: "{Name}",
//     content: [
//       {
//         type: "fields",
//         fieldInfos: [
//           {
//             fieldName: "Name"
//           },
//           {
//             fieldName: "Owner"
//           },
//           {
//             fieldName: "Length"
//           }
//         ]
//       }
//     ]
//   }
// };