/* eslint no-restricted-globals: 0*/

import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

let LOGIN_SUCCESS_PAGE = "/secret";
let LOGIN_FAILURE_PAGE = "/";

class Auth {
    userProfile;
    auth0 = new auth0.WebAuth({
        // the following three lines MUST be updated
        domain: 'geo-sapiens.auth0.com',
        audience: 'https://geo-sapiens.auth0.com/userinfo',
        clientID: 'a1I1RdvVsiFijjRx3a175z1KUiRG59Au',
        redirectUri: 'https://webapp.e-nundation.com//callback',
        // redirectUri: 'https://webapp.geosapiens.ca//callback',
        // redirectUri: 'https://deuxmontagnes.geosapiens.ca//callback',
        //redirectUri: 'http://localhost:3000//callback',
        responseType: 'token id_token',
        scope: 'openid profile'
    });

    constructor() {
        this.getProfile = this.getProfile.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    getProfile() {
        if (localStorage.getItem("id_token")) {
            return jwtDecode(localStorage.getItem("id_token"));
        } else
            return {};
    }

    getIdToken() {
        if (localStorage.getItem("id_token")) {
            return localStorage.getItem("id_token");
        } else
            return {};
        //return this.idToken;
    }

    isAuthenticated() {
        return new Date().getTime() < localStorage.getItem("expires_at");
    }

    signIn() {
        this.auth0.authorize();
        // console.log("this.auth0.authorize()", this.auth0.authorize())
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    localStorage.setItem("access_token", authResult.accessToken);
                    localStorage.setItem("id_token", authResult.idToken);
                    localStorage.setItem("expires_at", authResult.idTokenPayload.exp * 1000);

                    if (this.getProfile().sub) {
                        localStorage.setItem("geosapiens_app", "/" + this.getProfile().sub.split("|")[1]);
                        LOGIN_SUCCESS_PAGE = localStorage.getItem("geosapiens_app")
                    }

                    location.hash = "";
                    location.pathname = LOGIN_SUCCESS_PAGE;

                    resolve();
                } else if (err) {
                    location.pathname = LOGIN_FAILURE_PAGE;
                    console.log(err);
                    return reject(err);
                }
            });
        })

    }

    signOut() {
        // clear id token, profile, and expiration

        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("geosapiens_app")

        this.auth0.logout({
            returnTo: 'https://webapp.e-nundation.com',
            // returnTo: 'https://webapp.geosapiens.ca',
            // returnTo: 'https://deuxmontagnes.geosapiens.ca',
            clientID: 'a1I1RdvVsiFijjRx3a175z1KUiRG59Au',
        });
    }
}


export default Auth;