import React from "react";
import Header from "./Header";
import BottomPanel from "./BottomPanel";
import {WaterLevelMap} from "./WaterLevelMap";
import {MI} from "../constants/appsConstants";
import {Mentions} from "./Mentions";
import {Legende} from "./Legende";
import Validation from "./Validation";


export default class AppMilleIles extends React.Component {

  render() {
    return (
      <div className="app">
        <Header
          scenarioDepart={MI.scenarioDepart}
          nbScenario={MI.nbScenario}
          labels={MI.labels}
          btn2019={MI.btn2019}
          btnHier={MI.btnHier}
          btnTempsReel={MI.btnTempsReel}
          btnUnJour={MI.btnUnJour}
          btnTroisJours={MI.btnTroisJours}
          yearMarkers={MI.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={MI.scenarioDepart}
          labels={MI.labels}
          dataIsole={MI.dataIsole}
          dataSS={MI.dataSS}
          dataPrem={MI.dataPrem}
          dataTot={MI.dataTot}
          dataEvac={MI.dataEvac}
        />

        <WaterLevelMap
          configuration={MI}
          labels={MI.labels}
          store={this.props.store}
        />
        <Legende/>
        <Mentions/>
        <Validation/>
      </div>
    );
  }
}
