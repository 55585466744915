import React from "react";
import "./Photo.css";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";

const Photo = (props) => {
    return (
        <>
            <button id="btn-toggle-photo" onClick={props.onPhotoToggle}>
                <FontAwesomeIcon icon={faCamera} size="2x" color={props.togglePhoto ? "var(--color-cyan)" : "var(--color-bg-stats)"}/>
            </button>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        togglePhoto: state.photo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onPhotoToggle: () => dispatch(actionCreators.togglePhoto()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Photo);