export const buildingRenderer = {
  type: "unique-value",  // autocasts as new UniqueValueRenderer()
  field: "ETAT",
  uniqueValueInfos: [
    //     {
    //     value: "Normal",
    //     label: "Non inondé",
    //     symbol: {
    //         type: "simple-fill",  // autocasts as new SimpleFillSymbol()
    //         color: "green",
    //         outline: {width: 0}
    //     }
    // },
    {
      value: "Isolé (route inondée)",
      symbol: {
        type: "simple-fill",  // autocasts as new SimpleFillSymbol()
        color: "pink",
        outline: {width: 0}
      },
    }, {
      value: "Sous-sol inondé" || "Eau atteint bâtiment",
      label: "Sous-sol inondé",
      symbol: {
        type: "simple-fill",  // autocasts as new SimpleFillSymbol()
        color: "yellow",
        outline: {width: 0}
      },
    }, {
      value: "Sous-sol et premier plancher inondé" || "Premier plancher inondé (sans sous-sol)",
      label: "Sous-sol et premier plancher inondé",
      symbol: {
        type: "simple-fill",  // autocasts as new SimpleFillSymbol()
        color: "red",
        outline: {width: 0}
      },
    }],
};

export const buildingPtsRenderer = {
  type: "unique-value",  // autocasts as new UniqueValueRenderer()
  field: "ETAT",
  uniqueValueInfos: [
    {
      value: "Isolé (route inondée)",
      symbol: {
        type: "simple-marker",  // autocasts as new SimpleFillSymbol()
        color: "pink",
        // outline: {width: 0}
      },
    }, {
      value: "Sous-sol inondé" || "Eau atteint bâtiment",
      label: "Sous-sol inondé",
      symbol: {
        type: "simple-marker",  // autocasts as new SimpleFillSymbol()
        color: "yellow",
        // outline: {width: 0}
      },
    }, {
      value: "Sous-sol et premier plancher inondé" || "Premier plancher inondé (sans sous-sol)",
      label: "Sous-sol et premier plancher inondé",
      symbol: {
        type: "simple-marker",  // autocasts as new SimpleFillSymbol()
        color: "red",
        // outline: {width: 0}
      },
    }],
};

export const buildingFreestyleRenderer = {
  type: "simple",
  symbol: {
    type: "simple-fill",  // autocasts as new SimpleFillSymbol()
    color: "brown",
    outline: {width: 0}
  },
};

// export const streetRenderer = {
//   type: "unique-value",  // autocasts as new UniqueValueRenderer()
//   field: "STATUS",
//   uniqueValueInfos: [
//     {
//       value: "Inondé - Praticable",
//       symbol: {
//         type: "simple-line", // autocasts as new SimpleLineSymbol()
//         color: "yellow",
//         width: 3,
//         style: "solid"
//       }
//     }, {
//       value: "Inondé - Véhicules d'urgence seulement",
//       symbol: {
//         type: "simple-line", // autocasts as new SimpleLineSymbol()
//         color: "orange",
//         width: 3,
//         style: "solid"
//       }
//     }, {
//       value: "Inondé - Impraticable",
//       symbol: {
//         type: "simple-line", // autocasts as new SimpleLineSymbol()
//         color: "red",
//         width: 3,
//         style: "solid"
//       }
//     }]
// };
export const streetRenderer = {
  type: "unique-value",  // autocasts as new UniqueValueRenderer()
  field: "STATUS",
  uniqueValueInfos: [
    {
      value: "Inonde - Praticable",
      symbol: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "yellow",
        width: 3,
        style: "solid"
      }
    }, {
      value: "Inonde - Vehicules d'urgence seulement",
      symbol: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "orange",
        width: 3,
        style: "solid"
      }
    }, {
      value: "Inonde - Impraticable",
      symbol: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: "red",
        width: 3,
        style: "solid"
      }
    }]
};

// Pour sacs de sable
export const bagRenderer = {
  type: "simple",  // autocasts as new UniqueValueRenderer()
  symbol: {
    type: "simple-line",  // autocasts as new SimpleLineSymbol()
    width: 3,
    color: "black",
    style: "solid"
  }
};

// export const bagPtsRenderer = {
//     type: "simple",
//     symbol: {
//         type: "simple-marker",
//         // width: 3,
//         color: "black",
//         // style: "solid"
//     },
//     visualVariables: [{
//         type: "size",
//         field: "Volume",
//         // minDataValue: 0,
//         // maxDataValue: 100,
//         // minSize: 8,
//         // maxSize: 40
//     }, {
//         type: "size",
//         field: "Nb_Sacs",
//         minDataValue: 0,
//         maxDataValue: 1000,
//         minSize: 8,
//         maxSize: 40
//     }
//     ]
// };

// Pour Digues MegaSecur
// export const bagRenderer = {
//     type: "class-breaks",
//     field: "haut",
//     classBreakInfos: [
//         {
//             minValue: 0,
//             maxValue: 0.35,
//             label: "WL-1430/50",
//             symbol: {
//                 type: "simple-line",
//                 width: 3,
//                 color: "yellow",
//                 style: "solid"
//             }
//         }, {
//             minValue: 0.36,
//             maxValue: 0.81,
//             label: "WL-2030/50",
//             symbol: {
//                 type: "simple-line",
//                 width: 7,
//                 color: "yellow",
//                 style: "solid"
//             }
//         }, {
//             minValue: 0.82,
//             maxValue: 0.99,
//             label: "WL-3930/50",
//             symbol: {
//                 type: "simple-line",
//                 width: 10,
//                 color: "yellow",
//                 style: "solid"
//             }
//         }
//     ]
// };


// export const bagRenderer = {
//     type: "simple",  // autocasts as new UniqueValueRenderer()
//     symbol: {
//         type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
//         url: "https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png",
//         // url: "https://geosapiensdata.s3.ca-central-1.amazonaws.com/WL-1430_.PNG",
//         // url: "https://geosapiensdata.s3.ca-central-1.amazonaws.com/WL1430.svg",
//         width: "64px",
//         height: "64px"
//     }
// };


// export const riverRenderer = {
//     type: "simple",  // autocasts as new SimpleRenderer()
//     symbol: {
//         type: "simple-fill",  // autocasts as new SimpleMarkerSymbol()
//         color: "#17127B",
//         outline: {  // autocasts as new SimpleLineSymbol()
//             width: 0.5,
//             color: "white"
//         }
//     }
// }