import React, {Component} from "react";
import "./BlockStats.css";
import LineGraph from "./Histogram";
import styled from "styled-components";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";


const Title = styled.p`
font-size: 20px;
font-weight: bold;
text-align: center;
line-height: 12px;
margin: 3px;
padding: 3px;
`;
//color: ${props => (props.orange === true ? 'orange' : 'white')};
const Stats = styled.p`
color: ${props => (props.color)};
font-size: 36px;
font-weight: bolder;
margin: auto;
text-align: center;
`;

const BlockStats = (props) => {
    return (
        <div className="block_stats" onClick={props.graphToggle}>
            <Stats id={props.id} color={props.color}> 0 </Stats>
            <div className="block_stats_graph">
                {props.graphState ?
                    <LineGraph
                        scenarioDepart={props.scenarioDepart}
                        title={props.title}
                        labels={props.labels}
                        data={props.data}
                        dataLabel={props.statisticName}
                    />
                    : null}
            </div>
            <Title className="statsIsoleDescription">{props.statisticName}</Title>
        </div>
    )
};

export class StatsBar extends Component {
    render() {
        let typeMesure = this.props.typeMesureDebit ? "débit" : "niveau";
        return (
            <div className="left-panel" style={{display: this.props.toggleStats ? 'flex' : 'none'}}>
                <BlockStats
                    scenarioDepart={this.props.scenarioDepart}
                    labels={this.props.labels}
                    id={"statsIsole"}
                    color={"pink"}
                    statisticName={"Bâtiments isolés"}
                    title={"Bâtiment isolé en fonction du " + typeMesure}
                    data={this.props.dataIsole} colori={"color:'red'"}
                    graphState={this.props.toggleGraph.statsIsole}
                    graphToggle={() => this.props.onGraphToggle("statsIsole")}
                />
                <BlockStats
                    scenarioDepart={this.props.scenarioDepart}
                    labels={this.props.labels}
                    id={"statsSsInonde"}
                    color={"yellow"}
                    statisticName={"Sous-sols inondés"}
                    title={"Sous-sol inondé en fonction du " + typeMesure}
                    data={this.props.dataSS}
                    graphState={this.props.toggleGraph.statsSsInonde}
                    graphToggle={() => this.props.onGraphToggle("statsSsInonde")}
                />
                <BlockStats
                    scenarioDepart={this.props.scenarioDepart}
                    labels={this.props.labels}
                    id={"statsPremInonde"}
                    color={"red"}
                    statisticName={"Premiers planchers inondés"}
                    title={"Premier plancher inondé en fonction du " + typeMesure}
                    data={this.props.dataPrem}
                    graphState={this.props.toggleGraph.statsPremInonde}
                    graphToggle={() => this.props.onGraphToggle("statsPremInonde")}
                />
                <BlockStats
                    scenarioDepart={this.props.scenarioDepart}
                    labels={this.props.labels}
                    id={"statsTotalInonde"}
                    color={"red"}
                    statisticName={"Total des bâtiments inondés"}
                    title={"Total des bâtiments inondés en fonction du " + typeMesure}
                    data={this.props.dataTot}
                    graphState={this.props.toggleGraph.statsTotalInonde}
                    graphToggle={() => this.props.onGraphToggle("statsTotalInonde")}
                />
                <BlockStats
                    scenarioDepart={this.props.scenarioDepart}
                    labels={this.props.labels}
                    id={"statsNbEvac"}
                    color={"red"}
                    statisticName={"Personnes à évacuer"}
                    title={"Personnes à évacuer en fonction du " + typeMesure}
                    data={this.props.dataEvac}
                    graphState={this.props.toggleGraph.statsNbEvac}
                    graphToggle={() => this.props.onGraphToggle("statsNbEvac")}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        toggleStats: state.stats,
        typeMesureDebit: state.debit,
        toggleGraph: state.graph
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGraphToggle: (activeGraph) => dispatch(actionCreators.toggleGraph(activeGraph)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsBar);